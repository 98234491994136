(function (window, currentScript) {
	const version = "0.5.3";
	const apiHost = "https://gls.proxipick.com";
	let TFE;
	if (typeof window.TFE === "function" && window.TFE() === version) {
		TFE = window.TFE;
	} else {
		TFE = function () {
			return TFE.version;
		};
		TFE.log = function log(type, callFunction, ...args) {
			var _a;
			const header = "TFE::" + callFunction.name + " " + (type === "error" ? "Error:" : ":");
			(_a = console[type]) === null || _a === void 0 ? void 0 : _a.call(header, ...args);
		};
		TFE.isMobile = function isMobile() {
			return TFE.options.device === 'mobile' || ((window && window.innerWidth) || screen.width) <= 768;
		};
		TFE.isAcceptedNumber = function isAcceptedNumber(number) {
			let type = typeof number;
			return !(!number && 0 !== number || "boolean" === type || "symbol" === type) && ("bigint" === type || !isNaN(number));
		};
		TFE.events = {};
		TFE.options = {};
		TFE.version = version;
		TFE.apiHost = apiHost;
		TFE.scrollbarWidth = function () {
			// Creating invisible container
			const outer = document.createElement('div');
			outer.style.visibility = 'hidden';
			outer.style.overflow = 'scroll'; // forcing scrollbar to appear
			outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
			document.body.appendChild(outer);

			// Creating inner element and placing it in the container
			const inner = document.createElement('div');
			outer.appendChild(inner);

			// Calculating difference between container's full width and the child width
			const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

			// Removing temporary elements from the DOM
			outer.parentNode.removeChild(outer);

			return scrollbarWidth;
		};
		TFE.initializeEngageElement = initializeEngageElement;
		Object.assign(window, {TFE: TFE});
	}

	function init(options) {
		if (!document.body) {
			window.addEventListener("DOMContentLoaded", () => {
				init(options);
			});
			return;
		}

		let target = (options.target && document.querySelector(options.target)) || document.createElement("div");
		if (!target.parentElement) {
			document.body.appendChild(target);
		}

		Object.assign(TFE.options, options);

        fetchEngageData(options).then(function (data) {
            if (data && data.forEach) {
                data.forEach((item) => initializeEngageElement(item, {options, target}));

                if (TFE.options.target) {
                    document.querySelector(TFE.options.target).classList.add('engage-elements-in-target');
                }
            } else console.warn('data in Engage didnt fetched correctly');
        });
	}

	TFE.positionStyle = {
		CENTER: "top: 50%;left: 50%;transform: translate(-50%, -50%);position:fixed",
		CENTER_TOP: "top: 0;left: 50%;transform: translate(-50%);position:fixed",
		CENTER_BOTTOM: "bottom:0;left: 50%;transform: translate(-50%);position:fixed",
		CENTER_RIGHT: "top:50%;right: 0;transform: translate(0%,-50%);position:fixed",
		CENTER_LEFT: "top:50%;left: 0;transform: translate(0%,-50%);position:fixed",
		TOP_LEFT: "top:0;left:0;position:fixed",
		BOTTOM_RIGHT: "bottom:0;right:0;position:fixed",
		TOP_RIGHT: "top:0;right:0;position:fixed",
		BOTTOM_LEFT: "bottom:0;left:0;position:fixed",
	};

	TFE.events = Object.assign(TFE.events || {}, {
		'appear': new Event('appear'),
		'disappear': new Event('disappear')
	});

	function urlEngageData(options) {

		const host = options.host || TFE.apiHost;
		const endpoint = host + "/api/engage";
		let visit = getCookie("visit_number");
		const clientCode = options.clientCode,
			orderKey = options.orderKey,
            shipperId = options.shipperId;
		let params = new URLSearchParams({visit: visit});

		if (shipperId) {
			params.append("shipperId", shipperId);
		} else if (clientCode) {
			params.append("clientCode", clientCode);
		}

		if (orderKey) {
			params.append('order_key', orderKey);
		}

		const urlParams = new URLSearchParams(window.location.search);
		const isPreview = urlParams.get("isPreview");

		if (isPreview) {
			params.append("isPreview", isPreview);
			const urlPreviewDate = urlParams.get("preview_date");

			if (urlPreviewDate) {
				params.append("preview_date", urlPreviewDate);
			}

			visit = urlParams.get("visit") || visit;
		}

		params.set("visit", visit);
		return endpoint + "?" + params;
	}

	function fetchEngageData(options) {
		return Promise.resolve()
			.then(function () {
				const url = urlEngageData(options);
				return fetch(url, {
					method: "GET",
					headers: {
						Accept: "application/json"
					},
                    credentials: 'include'
				});
			})
			.then(function (response) {
				if (response.ok) {
					return response.json();
				}

				TFE.log("warn", TFE.init, response.statusText);
				return [];
			});
	}

    function createEngageElement(itemData, options, target) {
        let element;
        switch (itemData.element_type) {
            case "Image":
                element = createEngageImage(itemData, options);
                break;
            case "Video":
                element = createEngageVideo(itemData, options);
                break;
            case "Drawer":
                element = createEngageDrawer(itemData, options);
                break;
            case "DrawerImage":
                element = createEngageDrawerImage(itemData, options, target);
                break;
            case "Slider":
                element = createEngageSlider(itemData, options, target);
                break;
            case "Story":
                element = createEngageStory(itemData);
                break;
            case "StoryBubbles":
                element = createEngageStoryBubble(itemData);
                break;
            default:
                element = null;
                TFE.log("warn", TFE.init, "The type", itemData.element_type, " is not supported!");
        }

        if (element) {
            let _a;
            const margins = ((_a = itemData.content.margin) === null || _a === void 0 ? void 0 : _a[TFE.isMobile() ? 'mobile' : 'desktop']) || {};
            element.style.margin = TFE.isAcceptedNumber(margins === null || margins === void 0 ? void 0 : margins.center) ? `${margins.center}px` : `${margins.top || 0}px ${margins.right || 0}px ${margins.bottom || 0}px ${margins.left || 0}px`;
        }

        return element;
    }

	function initializeEngageElement(itemData, {options, target}) {

		let element;

        const insertEngageItem = () => {
            element = createEngageElement(itemData, options, target);

            if (element && target && element.parentElement !== target) {

                let closeBanner = element.querySelector(".close-button");
                if (closeBanner) {
                    closeBanner.addEventListener("click", (event) => {
                        ejectEngageItem();
                        event.preventDefault();
                    });
                }
                target.appendChild(element);
                element.dispatchEvent(TFE.events.appear);
            }
        };

        const ejectEngageItem = () => {
            if (element && target && element.parentElement === target) {
                element.dispatchEvent(TFE.events.disappear);
            }
        };

        if (!itemData.content.appear_after || !TFE.isAcceptedNumber(itemData.content.appear_after)) {
            insertEngageItem();
        } else {
            setTimeout(insertEngageItem, Number(itemData.content.appear_after) * 1000);
        }

        if (TFE.isAcceptedNumber(itemData.content.initializeEngageElement) && itemData.content.lifetime > 0) {
            let totalTime = (Number(itemData.content.appear_after) || 0) + Number(itemData.content.lifetime);
            setTimeout(ejectEngageItem, totalTime * 1000);
        }
	}

	function createEngageImage(itemElement, options) {
		const itemData = itemElement.content;
		const itemTitle = itemElement.title || itemData.files[0].name;
		const element = document.createElement("div");

		const positioningStyle = /top/i.test(itemData.position) ? TFE.positionStyle.CENTER_TOP :
			/bottom/i.test(itemData.position) ? TFE.positionStyle.CENTER_BOTTOM : TFE.positionStyle.CENTER;
        let style = `display:none;opacity: ${itemData.opacity};${positioningStyle};z-index: 10003;`;
        if(options?.customStyle){
            style += options.customStyle;
        }

		const isMobile = TFE.isMobile();

		// type and width, height always auto, max-height no more than 90% of screen
		let type = isMobile ? itemData.mobile_size_type : itemData.desktop_size_type;
		const isFix = type === 'fix';
		const isPercentage = type === 'percentage';

		const width = (isMobile ? itemData.mobile_size_width : itemData.desktop_size_width) + (isFix ? "px" : (isPercentage ? "%" : "vw"));

		element.classList.add("block-bad", "banner_view");
		element.setAttribute("style", style);
		element.setAttribute("data-id", itemElement.uuid);
		element.setAttribute("data-style-name", itemData.position);
		element.innerHTML = itemData.files[0].src ? `<a href="${itemData.files[0].click_link || "#"}" target="_blank" rel="nofollow"
            ${itemData.files[0].click_link ? "" : 'onclick="return false;"'}>
            ${itemData.closable === "true" ? '<span class="close-button"><i class="tf-icon tf-icon-times"></i></span>' : ""}
            <img class="engage-banner" src="${options.host || ''}${itemData.files[0].src}" alt="${itemTitle}" data-gtm-title="${itemTitle}"
            	 style="width:${width};height:auto; max-width: 100vw; max-height: calc(100vh - var(--page-header-height, 0px)); object-fit: contain" />
        </a>` : "";
		element.addEventListener('appear', function (event) {
			element.style.display = '';
			resizeEngageBanner(element);
		});
		element.addEventListener('disappear', function (event) {
			if (element.parentElement) {
				element.parentElement.removeChild(element);
			}
		});
		return element;
	}

    function loadYoutubeIframeApi() {
        if(!document.querySelector('script[src="https://www.youtube.com/iframe_api"]')) {
            // This code loads the IFrame Player API code asynchronously.
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
    }

	function createEngageVideo(itemElement, options) {
		const itemData = itemElement.content;
		const itemTitle = itemElement.title;
		const element = document.createElement("div");
		const positioningStyle = /top/i.test(itemData.position) ? TFE.positionStyle.CENTER_TOP :
			/bottom/i.test(itemData.position) ? TFE.positionStyle.CENTER_BOTTOM : TFE.positionStyle.CENTER;
		const style = `display:none;opacity: ${itemData.opacity};${positioningStyle};z-index:10005`;

		const isMobile = TFE.isMobile();
		let type = isMobile ? itemData.mobile_size_type : itemData.desktop_size_type;
		const isFix = type === 'fix';

		const width = (isMobile ? itemData.mobile_size_width : itemData.desktop_size_width) + (isFix ? "px" : "vw");
		const height = `calc(9 / 16 * ${width})`;

		const regex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
		let content, fileSource = itemData.files[0].src;
		if (regex.test(fileSource)) {
            loadYoutubeIframeApi();

			fileSource += `?`;
			fileSource += itemData.autoplay === "true" ? "&autoplay=1&mute=1&playsinline=1" : "&autoplay=0";
			fileSource += itemData.auto_replay === "true" ? "&loop=1" : "&loop=0";
			fileSource += itemData.controls === "true" ? "&controls=1" : "&controls=0";
			content = `<iframe src="${fileSource}" style="width: ${width};height: ${height};border: 0;" class="engage-video-iframe" data-gtm-title="${itemTitle}" allowfullscreen></iframe>`;
		} else {
			content = `<video class="engage-video" style="width:${width};" data-gtm-title="${itemTitle}"
                ${itemData.autoplay === "true" ? "autoplay" : ""}
                ${itemData.auto_replay === "true" ? "loop" : ""}
                ${itemData.controls === "true" ? "controls" : ""}
                muted playsinline>
                <source src="${options.host}${fileSource}" type="video/mp4">
            </video>`;
		}
		element.classList.add("block-bad", "banner_view");
		element.setAttribute("style", style);
		element.setAttribute("data-id", itemElement.uuid);
		element.setAttribute("data-style-name", itemData.position);
		element.innerHTML = `<a href="${itemData.files[0].click_link || "#"}" target="_blank" rel="nofollow"
            ${itemData.files[0].click_link ? "" : 'onclick="return false;"'}>
            ${itemData.closable === "true" ? '<span class="close-button"><i class="tf-icon tf-icon-times"></i></span>' : ''}
            ${content}
            ${itemData.files[0].click_link ? '<div style="position: absolute;top:0;width: 100%;height:100%;" class="embed_anchor"></div>' : ""}
        </a>`;
		let embedAnchor = element.querySelector(".embed_anchor");
		if (embedAnchor) {
			embedAnchor.addEventListener("click", (event) => {
				if (embedAnchor && embedAnchor.parentElement) {
					embedAnchor.parentElement.removeChild(embedAnchor);
				}
			});
		}
		element.addEventListener('appear', function (event) {
			element.style.display = "";
			resizeEngageVideo(element);
		});
		element.addEventListener('disappear', function (event) {
			if (element.parentElement) {
				element.parentElement.removeChild(element);
			}
		});
		return element;
	}

	function createEngageDrawer(itemElement, options) {
		const itemData = itemElement.content;
		const element = document.createElement("div");
		const device = TFE.isMobile() ? "mobile" : "desktop";
		const drawerPosition = itemData.position ? itemData.position.toLowerCase() : 'right';
		const drawerBackgroundColor = itemData.backgroundColor ? itemData.backgroundColor.toLowerCase() : 'initial';
		let drawerSize, size_metric;
		if (itemData.position === 'TOP' || itemData.position === 'BOTTOM') {
			size_metric = itemData[device + "_width_metric"] === 'px' ? 'px' : 'vh';
			drawerSize = itemData[device + "_prevent_width"] === 'disabled' ? '25vh' : itemData[device + "_width"] + size_metric;
		} else {
			size_metric = itemData[device + "_width_metric"] === 'px' ? 'px' : 'vw';
			drawerSize = itemData[device + "_prevent_width"] === 'disabled' ? '25vw' : itemData[device + "_width"] + size_metric;
		}
		const style = `opacity: ${itemData.opacity};--tf-drawer-size: min(${drawerSize}, 100%);`;
		const thinScrollableStyle = "body{padding:0;margin:0;outline-offset:-2px;scrollbar-width:thin;overflow:auto;-ms-overflow-style:-ms-autohiding-scrollbar;}@media(hover:hover){body::-webkit-scrollbar-track{background-color:#f0f0f0;}body::-webkit-scrollbar{width:6px;height:6px;}body::-webkit-scrollbar-thumb{background-color:#888;}}";
		let drawerContent = itemData.content_html;
		if (itemData.content_type === 'image') {
			const link = itemData.files[0].click_link ? `href='${itemData.files[0].click_link}'` : "onclick='return false;'";
			const sourceImage = options.host + itemData.files[0].src;
			const imgStyle = 'display: block; width: 100vw; height: 100vh; background: url("' + sourceImage + '") no-repeat center; background-size: contain';
			drawerContent = `<a ${link} target='_blank' rel='nofollow' style='${imgStyle}' ></a>`;
		}
		element.classList.add("banner_view", "banner_drawer_" + drawerPosition);
		element.setAttribute("style", style);
		element.setAttribute("data-id", itemElement.uuid);
		const closeElementHTML = itemData.closable === "true" ? `<div class="drawer-close" data-position="${drawerPosition}" style="z-index: 10001" >
            <i class="tf-icon tf-icon-caret-${drawerPosition === 'top' ? 'up' : (drawerPosition === 'bottom' ? 'down' : drawerPosition)}" style="margin: auto;"></i>
        </div>` : '';
        element.innerHTML = `<div class="banner_drawer collapsed" style="z-index: 10001">
            <div class="drawer-content" style="background-color: ${drawerBackgroundColor};" >
                <iframe style="border: none;" width="100%" height="100%"></iframe>
            </div>
        </div>${closeElementHTML}`;
		const iframe = element.querySelector('iframe');
		if (iframe) {
			iframe.srcdoc = "<style>" + thinScrollableStyle + "</style>" + drawerContent;
		}
		const drawerCloseElement = element.querySelector('.drawer-close');
		if (drawerCloseElement) {
			adaptDrawerCloseIconClass(drawerCloseElement);
			drawerCloseElement.addEventListener('click', function (event) {
				const target = event.currentTarget;
				adaptDrawerCloseIconClass(target);
				const bannerDrawer = target.parentElement && target.parentElement.querySelector('.banner_drawer');
				if (bannerDrawer) {
					bannerDrawer.classList.toggle('collapsed');
				}
			});
		}
		element.addEventListener('appear', function (event) {
			const target = event.currentTarget;
			const bannerDrawer = target.querySelector('.banner_drawer');
			const closeDrawer = target.querySelector('.drawer-close');
			if (bannerDrawer) {
				bannerDrawer.classList.remove("collapsed");
				if (closeDrawer)
					adaptDrawerCloseIconClass(closeDrawer);
			}
		});
		element.addEventListener('disappear', function (event) {
			const target = event.currentTarget;
			const bannerDrawer = target.querySelector('.banner_drawer');
			const closeDrawer = target.querySelector('.drawer-close');
			if (bannerDrawer) {
				bannerDrawer.classList.add("collapsed");
				if (closeDrawer)
					adaptDrawerCloseIconClass(closeDrawer);
			}
		});
		return element;
	}

	function getImageSizeAsync(imgSrc, callback) {
		const newImg = new Image();

		newImg.onload = () => {
			callback(newImg);
		};

		newImg.src = imgSrc; // this must be done AFTER setting onload
	}

	function createEngageDrawerImage(itemElement, options, container) {
		// CONSTANTS
		const MAX_DRAWER_WIDTH = TFE.isMobile() ? 0.85 : 0.30;
		const MAX_DRAWER_HEIGHT = TFE.isMobile() ? 0.85 : 0.65;

		let containerWidth = container.offsetWidth || window.innerWidth;
		let containerHeight = container.offsetHeight || window.innerHeight;

		const itemData = itemElement.content;
		const itemTitle = itemElement.title || 'drawer images, banner';
		const element = document.createElement("div");
		const device = TFE.isMobile() ? "mobile" : "desktop";
		const drawerPosition = itemData.position ? itemData.position.toLowerCase() : 'right';

		const isTopBottom = (drawerPosition === 'top' || drawerPosition === 'bottom');
		const isMobile = device === 'mobile';
		const isFix = (isMobile ? itemData.mobile_size_type : itemData.desktop_size_type) === 'fix';

		let style = '--tf-drawer-size: 100%';

		const link = itemData.files[0].click_link ? `href='${itemData.files[0].click_link}'` : "onclick='return false;'";
		const sourceImage = `${options.host || ''}${itemData.files[0].src}`;

		let imgStyle = isTopBottom ? 'height:100%;object-fit:contain;' : isFix ? 'object-fit:contain;' : 'width:100%;object-fit:contain;';

		getImageSizeAsync(sourceImage, img => {

			let w, h, size;

			w = containerWidth > img.width ? img.width : containerWidth;
			h = img.height * (w / img.width);

			const imageRatio = img.width / img.height;

			if (isTopBottom) {
				const maxHeight = MAX_DRAWER_HEIGHT * containerHeight;
				const containerRatio = (containerWidth + TFE.scrollbarWidth()) / maxHeight;

				size = maxHeight;
				if (imageRatio > containerRatio) {
					size = maxHeight * (containerRatio / imageRatio);
				}
			} else {
				const maxWidth = MAX_DRAWER_WIDTH * containerWidth;
				const containerRatio = maxWidth / containerHeight;

				size = maxWidth;
				if (imageRatio < containerRatio && !isFix) {
					size = maxWidth * (imageRatio / containerRatio);
				}

				const banner = element.querySelector('.banner_drawer_image');
				if (banner) {
					banner.style.setProperty('transform', `translateY(calc(${containerHeight / 2}px - 50%))`);

				}

				if (isFix) {
					const fixDrawer = element.querySelector('.banner_drawer_image');
					if (fixDrawer) {
						if (imageRatio < containerRatio) {
							fixDrawer.style.height = "100%";
							fixDrawer.classList.add('scrollable-y');
						}

						fixDrawer.style.width = `${size}px`;

						const drawerImg = fixDrawer.querySelector('img');
						if (drawerImg) {
							drawerImg.style.width = `${size}px`;
						}
					}
				}
			}

			if (!isFix && h >= containerHeight) size = containerHeight * img.width / img.height;

			element.style.setProperty(
				"--tf-drawer-size",
				`min(calc(${size}px + var(--tf-drawer-offset)), 100%)`
			);
		});

		element.classList.add("banner_view", "banner_drawer_" + drawerPosition);
		element.setAttribute("style", style);
		element.setAttribute("data-id", itemElement.uuid);
		const closeElementHTML = `<div class="drawer-close" data-position="${drawerPosition}" style="z-index: 10004">
            <i class="tf-icon tf-icon-caret-${drawerPosition === 'top' ? 'up' : (drawerPosition === 'bottom' ? 'down' : drawerPosition)}" style="margin: auto;"></i>
        </div>`;
		element.innerHTML = `<div class="banner_drawer banner_drawer_image ${isFix ? 'banner_drawer_fix' : ''} collapsed"
                style="z-index: 10004; ${isFix && !isTopBottom ? `overflow-y: auto;height:initial;` : ''}">
            <div class="drawer-content" >
                <a ${link} target='_blank' rel='nofollow'>
                    <img id="engage_drawer_image_${itemElement.uuid}" src="${sourceImage}" alt="${itemTitle}" data-gtm-title="${itemTitle}" style="${imgStyle}"/>
                </a>
            </div>
        </div>${closeElementHTML}`;

		const drawerCloseElement = element.querySelector('.drawer-close');
		if (drawerCloseElement) {
			adaptDrawerCloseIconClass(drawerCloseElement);
			drawerCloseElement.addEventListener('click', function (event) {
				const target = event.currentTarget;
				adaptDrawerCloseIconClass(target);
				const bannerDrawer = target.parentElement && target.parentElement.querySelector('.banner_drawer');
				if (bannerDrawer) {
					bannerDrawer.classList.toggle('collapsed');
				}
			});
		}
		element.addEventListener('appear', function (event) {
			const target = event.currentTarget;
			const bannerDrawer = target.querySelector('.banner_drawer');
			const closeDrawer = target.querySelector('.drawer-close');
			if (bannerDrawer) {
				bannerDrawer.classList.remove("collapsed");
				if (closeDrawer)
					adaptDrawerCloseIconClass(closeDrawer);
			}
		});
		element.addEventListener('disappear', function (event) {
			const target = event.currentTarget;
			const bannerDrawer = target.querySelector('.banner_drawer');
			const closeDrawer = target.querySelector('.drawer-close');
			if (bannerDrawer) {
				bannerDrawer.classList.add("collapsed");
				if (closeDrawer)
					adaptDrawerCloseIconClass(closeDrawer);
			}
		});
		return element;
	}

	function adaptDrawerCloseIconClass(target) {
		let drawerPosition = target.getAttribute('data-position'),
			adaptedIconToggle = "tf-icon-caret-left tf-icon-caret-right";
		if (drawerPosition === "top" || drawerPosition === "bottom") {
			adaptedIconToggle = "tf-icon-caret-up tf-icon-caret-down";
		}
		const iconElement = target.querySelector('i');
		if (iconElement) {
			adaptedIconToggle.split(' ').forEach(className => iconElement.classList.toggle(className));
		}
	}

	function createEngageSlider(itemElement, options, container) {
		const itemData = itemElement.content;
		const itemTitle = itemElement.title;
		const element = document.createElement("div");

		const positioningStyle = /top/i.test(itemData.position) ? TFE.positionStyle.CENTER_TOP :
			/bottom/i.test(itemData.position) ? TFE.positionStyle.CENTER_BOTTOM : TFE.positionStyle.CENTER;
		const style = `display:none;opacity: ${itemData.opacity};${positioningStyle};z-index: 10002;`;

		const isMobile = TFE.isMobile();
		const device = TFE.isMobile() ? "mobile" : "desktop";

		let type = isMobile ? itemData.mobile_size_type : itemData.desktop_size_type;
		const isFix = type === 'fix';

		const width = (isMobile ? itemData.mobile_size_width : itemData.desktop_size_width) + (isFix ? "px" : "vw");
		const height = "auto";

		const showControls = itemData.controls === "true";
		const timer = Number(itemData.timer);

		// style="height: ${height};"
		const slides = itemData.files.map(file => `<div class="engage-slider slide-in" >
                <a href="${file.click_link || "#"}"  target="_blank" rel="nofollow" class="ga-slider-link" ${file.click_link ? "" : 'onclick="return false;"'}>
                    <img src="${options.host}${file.src}" alt="${itemTitle || file.name}" data-gtm-title="${itemTitle || file.name}" class="ga-slider-img" style="width:100%">
                </a>
            </div>`).join("");
		const dotes = showControls ? itemData.files.map((_file, index) => `<span class="tfe-dot" data-index="${index}"></span>`).join("") : '';
		element.classList.add("block-bad", "banner_view");
		element.setAttribute("style", style);
		element.setAttribute("data-id", itemElement.uuid);
		element.setAttribute("data-style-name", itemData.position);
		element.setAttribute('data-current-index', "0");


		element.innerHTML = `<div class="slideshow-container"
                style="width:${width}; height: ${height};max-height: ${container.offsetHeight}px" >
            ${slides}
            ${itemData.closable === "true" ? '<span class="close-button"><i class="tf-icon tf-icon-times"></i></span>' : ""}
            ${showControls ? `
                <a class='prev'>&#10094;</a>
                <a class='next'>&#10095;</a>
                <div class='slideshow-dots'>${dotes}</div>
            ` : ""}
        </div>`;
		let timeoutRef;
		showSlides(0);

		function slideUpdate() {
			if (timer) {
				timeoutRef = setTimeout(() => {
					showSlides('next');
				}, timer * 1000);
			}
		}

		element.addEventListener('click', function (event) {
			const target = event.target;
			if (target.classList.contains('dot')) {
				showSlides(Number(target.getAttribute('data-index') || 0));
			} else if (target.classList.contains('prev')) {
				showSlides("prev");
			} else if (target.classList.contains('next')) {
				showSlides("next");
			}
		});

		function showSlides(index) {
			if (!element)
				return;
			clearTimeout(timeoutRef);

			let slideIndex;
			const slides = element.getElementsByClassName("engage-slider");
			const dots = element.getElementsByClassName("tfe-dot");

			if (typeof index === 'number' && !isNaN(index)) {
				slideIndex = index;
			} else {
				slideIndex = Number(element.getAttribute('data-current-index') || 0);
				if (index === 'next')
					slideIndex += 1;
				else if (index === 'prev')
					slideIndex -= 1;
			}

			if (slides.length > 0) {
				slideIndex = (slideIndex + slides.length) % slides.length;
				element.setAttribute('data-current-index', slideIndex.toString());

				const actElement = slides[slideIndex];
				const passElement = slides[(slideIndex + (index === 'prev' ? 1 : -1) + slides.length) % slides.length];

				for (let slide of slides) {
					if (slide.isSameNode(actElement)) {
						slide.classList.remove('slide-out');
						slide.style.display = "block";
						if (index == 'prev') {
							slide.classList.add('slide-reverse');
						} else {
							slide.classList.remove('slide-reverse');
						}
					} else if (slide.isSameNode(passElement)) {
						slide.classList.add('slide-out');
						if (index == 'prev') {
							slide.classList.add('slide-reverse');
						} else {
							slide.classList.remove('slide-reverse');
						}
					} else {
						slide.classList.remove('slide-out');
						slide.style.display = 'none';
					}
				}

				if (dots.length > 0) {
					for (let dot of dots) {
						dot.classList.remove('tfe-active');
					}
					dots[slideIndex].classList.add('tfe-active');
				}
			}

			slideUpdate();
		}

		element.addEventListener('appear', function (event) {
			element.style.display = "";
			resizeEngageSlider(element, container);
		});

		element.addEventListener('disappear', function (event) {
			if (element.parentElement) {
				element.parentElement.removeChild(element);
			}
			clearTimeout(timeoutRef);
		});
		return element;
	}

    function createEngageStory(itemElement) {
        const element = document.createElement("div");
        const itemData = itemElement.content;
        const itemTitle = itemElement.title;

        const style = `opacity: ${itemData.opacity}; height:100%; pointer-events: all;position: relative; z-index:10006`;

        const internalCloseElement = itemData.closable === "true" ? '<div class="internal-story-close"></div>': '';

        element.setAttribute("style", style);
        element.setAttribute("data-id", itemElement.uuid);
        element.innerHTML = `${internalCloseElement}<iframe src="${itemData.story_url}" data-gtm-title="${itemTitle}"
                       allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *;"
                       style="display: block; width:100%; height:100%;border: none; border-radius: 10px; background: transparent; margin: 0 auto">
        </iframe>`;

        element.querySelector('.internal-story-close').addEventListener('click', function () {
            element.remove();
        })

        return element;
    }

    function createEngageStoryBubble(itemElement) {
        const itemData = itemElement.content;

        const widgetScript = document.createElement('script');
        widgetScript.src = "https://widget.snackeet.com/script.js";
        widgetScript.defer = true;
        widgetScript.dataset.snktId = String(itemData.story_widget_id);
        currentScript.insertAdjacentElement('afterend', widgetScript);

        let snakeetElement = '';
        waitForElement(`[id="${itemData.story_widget_id}"]`).then(snakeetSelector => {

            if (snakeetSelector !== null && snakeetSelector.nodeType === Node.ELEMENT_NODE) {

                waitForElement('#shipper-logo-panel').then(elementSelector => {
                    snakeetElement = snakeetSelector;

                    document.getElementById('engage-content').insertAdjacentElement("beforeend", snakeetElement);

                    snakeetElement.setAttribute('style', 'pointer-events: all');
                    snakeetElement.setAttribute('data-id', itemElement.uuid);
                    if (elementSelector === null) {
                        snakeetElement.style.top = document.querySelector('.navbar-fixed-top_landing')?.offsetHeight - 15 + 'px'
                        snakeetElement.style.position = 'fixed';
                        snakeetElement.style.width = '100%';
                    } else {
                        snakeetElement.style.top = elementSelector?.offsetHeight - 15 + 'px'
                        snakeetElement.style.position = 'relative';
                    }
                    snakeetElement.style.background = itemData.backgroundColor;
                    snakeetElement.style.margin = '0 auto';
                });
            }
        });

        return snakeetElement;
    }

    function waitForElement(selector, timeout = 2000) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                return;
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });

            setTimeout(() => {
                observer.disconnect();
                resolve(null); // Resolve with null after timeout
            }, timeout);
        });
    }

	function getCookie(cname) {
		let name = cname + "=";
		let ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	function resizeEngageBanner(element) {
		const item = element.querySelector('.engage-banner, .engage-video');
		if (item) {
			if (item.clientHeight > window.innerHeight) {
				item.style.width = 'auto';
				item.style.height = '90vh';
			}

			if (item.clientWidth > window.innerWidth) {
				item.style.width = '100vw';
				item.style.height = 'auto';
			}
		}
	}

	function resizeEngageVideo(element) {
		const item = element.querySelector('.engage-video');
		if (item) {
			resizeEngageBanner(element);
		} else {
			resizeEngageVideoIframe(element);
		}
	}

	function resizeEngageVideoIframe(element) {
		const item = element.querySelector('.engage-video-iframe');

		if (!item) return;

		let videoHeight = item.clientHeight,
			videoWidth = item.clientWidth,
			screenHeight = window.innerHeight,
			screenWidth = window.innerWidth;

		// ratio to replace next width if is over fit
		let sigmaRatio = 1;
		let isOverFit = false;

		// should adapt width
		if (videoWidth > screenWidth) {
			isOverFit = true;
			sigmaRatio *= screenWidth / videoWidth;
		}

		// should adapt height based to new width size
		if (videoHeight * sigmaRatio > screenHeight) {
			isOverFit = true;
			sigmaRatio *= screenHeight / videoHeight;
		}

		if (isOverFit) {
			item.style.width = sigmaRatio * videoWidth + "px";
			item.style.height = Math.round((9 * sigmaRatio * videoWidth) / 16) + "px";
		}
	}

	function resizeEngageSlider(element, container) {

		const slideshow = element.querySelector('.slideshow-container');

		// we consider the images are uniform in size
		const img = slideshow && slideshow.querySelector('.engage-slider img');

		if (!img) return; // there no image

		if (img.complete) {
			adaptImageWithContainer(img.naturalWidth, img.naturalHeight);
		} else {
			// because on sometime the image may take a long time to get
			img.addEventListener('load', () => adaptImageWithContainer(img.naturalWidth, img.naturalHeight));
		}

		function adaptImageWithContainer(imgW, imgH) {
			const containerW = container.offsetWidth;
			const imgRatio = imgW / imgH;
			let curImgW = Math.min(slideshow.offsetWidth, containerW);
			let curImgH = curImgW / imgRatio;
			// adapt slideshow to image
			slideshow.style.width = curImgW + 'px';
			slideshow.style.height = curImgH + 'px';
            slideshow.style.maxHeight = curImgH + 'px';

		}
	}

	function resizeEngageElements() {
		const engageElements = document.querySelectorAll(".engage-banner, .engage-video");
		if (engageElements) {
			for (let item of engageElements) {
				resizeEngageBanner(item);
			}
		}

		const engageVideoIframe = document.querySelectorAll(".engage-video-iframe");
		if (engageVideoIframe) {
			for (let item of engageVideoIframe) {
				resizeEngageVideoIframe(item);
			}
		}

		const carousel = document.querySelectorAll('.engage-slider');
		if (carousel) {
			for (let carouselItem of carousel) {
				resizeEngageSlider(carouselItem);
			}
		}
	}

	window.addEventListener("resize", resizeEngageElements);

	TFE.init = init;
})(window, document.currentScript);
